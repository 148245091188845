<template>
  <div class="admin-list">
    <PageTitle :title="$t('page.branchSetting.title')" :btn="$t('common.button.create.text')" @btnClick="openDialog('create')" />
    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable :placeholder="$t('branchSetting.searchName.placeholder')" @keypress.enter.native="refresh(true)" @clear="refresh(true)">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="shopList" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('branchSetting.table.name.title')" prop="name" align="center" />
        <BaseElTableColumn :label="$t('branchSetting.table.phone.title')" prop="phone" align="center" />
        <BaseElTableColumn v-if="isOverseasShop" :label="$t('branchSetting.table.currency.title')" prop="currency" align="center" />
        <BaseElTableColumn v-if="isOverseasShop" :label="$t('branchSetting.table.timezone.title')" prop="timezone" align="center">
          <template slot-scope="scope">
            {{ shopTimezone(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('branchSetting.table.address.title')" prop="address" align="center" />
        <BaseElTableColumn :label="$t('branchSetting.table.email.title')" prop="email" align="center">
          <template slot-scope="scope">
            {{ scope.row.email || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('branchSetting.table.contract.title')" align="center">
          <template slot-scope="scope">
            {{ shopContractName(scope.row) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              hideDelete
              @edit="openDialog('update', scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="shopsCount"
        @pageChange="changePage"
      />
    </section>

    <!-- Dialog -->
    <EditBranchShopModal
      v-if="showDialog"
      :selectRow="selectRow"
      @close="showDialog = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetShops, GetShopsCount, GetShopById, GetIndustryCategories } from '@/api/shop'
import EditBranchShopModal from './components/EditBranchShopModal.vue'

// Utils
import { pageStartIndex } from '@/utils/table'
import jpCities from '@/assets/data/japanCities'

import { currencyOptions, timezoneOptions } from '@/config/branchSetting'
import { get } from 'lodash'
import store from '@/store'
import { computed, ref, provide } from 'vue'
import { useOrg } from '@/use/useOrg'

export default {
  name: 'BranchShopList',
  components: { EmptyBlock, EditBranchShopModal },
  setup () {
    const japanCities = ref(jpCities)
    const { dashboardConfig } = useOrg()
    const cities = ref([])
    const selectRow = ref(null)
    const isEdit = computed(() => get(selectRow.value, 'id'))
    const isOverseasShop = computed(() => get(store.state, 'org.isOverseasShop'))
    const showControl = computed(() => {
      const controls = {
        formItem: {
          timezone: true,
        },
        formItemHint: {
          timezone: true,
        },
      }

      if (get(dashboardConfig.value, 'orgModulePermission.enableShopTimezone')) {
        controls.formItem.timezone = true
      }
      if (isEdit.value) {
        controls.formItemHint.timezone = false
      }

      return controls
    })
    const industryCategories = ref([])

    const getIndustryCategories = async () => {
      const [res, err] = await GetIndustryCategories()
      if (err) {
        window.$message.error(err)
        return
      }
      industryCategories.value = res.industryCategories
    }
    const shopTimezone = (row) => {
      return get(timezoneOptions[row.timezone], 'label', '-')
    }

    provide('industryCategories', industryCategories)
    return {
      isOverseasShop,
      japanCities,
      cities,
      industryCategories,
      getIndustryCategories,
      selectRow,
      currencyOptions,
      timezoneOptions,
      shopTimezone,
      showControl,
      isEdit,
    }
  },
  data: () => ({
    loading: false,
    btnLoading: false,
    userSearch: '',
    nameSearch: '',
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    roleList: ['orgAdmin'],

    shopList: [],
    shopsCount: 0,

    // Dialog
    showDialog: false,
    dialogType: 'create',
  }),

  computed: {
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      await Promise.allSettled([
        this.getShops(),
        this.getShopsCount(),
        this.getIndustryCategories(),
      ])
    },
    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage () {
      await this.getShops()
    },
    shopContractName (row) {
      return get(row, 'ContractPlan.name', '-')
    },

    openDialog (type, row) {
      this.dialogType = type
      this.showDialog = true
      this.selectRow = row
    },

    async findShop () {
      const [res, err] = await GetShopById(this.selectRow.id)
      if (err) return this.$message.error(err || err.msg)
      this.selectRow = res
    },

    //= > 取得店家
    async getShops () {
      this.loading = true
      try {
        this.shopList = await GetShops({
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.shopList = this.shopList.map(shop => {
          if (shop.address) {
            shop.address = shop.address.replace(/,/g, '')
          }
          return shop
        })
        this.loading = false
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得總店家數量
    async getShopsCount () {
      try {
        this.shopsCount = await GetShopsCount({
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },

  },
}
</script>

<style scoped lang="scss">
::v-deep .grid .el-input {
  @apply w-auto;
}
</style>
