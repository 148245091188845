<template>
  <BaseDialog
    :title="displayData.modal.title"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm
      ref="formRef"
      v-loading="!loaded"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem>
        <div>
          <p>{{ $t('branchSetting.dialog.imageSetting.title') }}</p>
          <p>{{ $t('branchSetting.dialog.imageSetting.subTitle') }}</p>
        </div>
        <UploadButton
          :img="formData.img"
          :isAvatar="true"
          @change="onImaChange"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('branchSetting.dialog.branchName.title')" prop="name">
        <BaseElInput v-model="formData.name" :placeholder="$t('branchSetting.dialog.branchName.placeholder')" />
      </BaseElFormItem>

      <BaseElFormItem :label="$t('branchSetting.dialog.phone.title')" prop="phone">
        <BaseElInput v-model="formData.phone" :placeholder="$t('branchSetting.dialog.phone.placeholder')" />
      </BaseElFormItem>

      <BaseElFormItem :label="$t('branchSetting.dialog.address.title')" prop="address">
        <BaseElInput v-model="formData.address" :placeholder="$t('branchSetting.dialog.address.placeholder')" />
      </BaseElFormItem>

      <BaseElFormItem :label="$t('branchSetting.dialog.googleMapLink.title')" prop="googleMapLink">
        <BaseElInput v-model="formData.googleMapLink" :placeholder="$t('branchSetting.dialog.googleMapLink.placeholder')" />
      </BaseElFormItem>

      <BaseElFormItem :label="$t('branchSetting.dialog.email.title')" prop="email">
        <BaseElInput v-model="formData.email" :placeholder="$t('branchSetting.dialog.email.placeholder')" />
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.formItem.currency" :label="$t('branchSetting.dialog.currency.title')" prop="currency">
        <BaseElSelect
          v-model="formData.currency"
          :placeholder="$t('branchSetting.dialog.currency.placeholder')"
          :disabled="disabledControl.formItem.currency"
        >
          <BaseElSelectOption
            v-for="option in currencyOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showControl.formItem.timezone" :label="$t('branchSetting.dialog.timezone.title')" prop="timezone">
        <template #label>
          <div class="inline-flex flex-col" style="gap: 4px">
            <p>{{ $t('branchSetting.dialog.timezone.title') }}</p>
            <p v-show="showControl.formItemHint.timezone" class="text-sub text-gray-50">
              {{ displayData.formItemHint.timezone }}
            </p>
          </div>
        </template>
        <BaseElSelect
          v-model="formData.timezone"
          :placeholder="$t('branchSetting.dialog.timezone.placeholder')"
          :disabled="disabledControl.formItem.timezone"
        >
          <BaseElSelectOption
            v-for="option in timezoneOptions"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem :label="$t('branchSetting.dialog.industryCategory.title')" prop="industry">
        <div class="grid gap-[8px] grid-flow-col">
          <BaseElSelect v-model="formData.industryCategory" :placeholder="$t('branchSetting.dialog.industryCategory.placeholder')" @change="updateIndustry">
            <BaseElSelectOption
              v-for="option in industryCategories"
              :key="option.key"
              :value="option.key"
              :label="option.name"
            />
          </BaseElSelect>
          <BaseElSelect v-model="formData.industry" :disabled="!formData.industryCategory" :placeholder="$t('branchSetting.dialog.subIndustryCategory.placeholder')">
            <BaseElSelectOption
              v-for="option in subIndustryCategories"
              :key="option.key"
              :value="option.key"
              :label="option.name"
            />
          </BaseElSelect>
        </div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('branchSetting.dialog.contract.title')" prop="ContractPlanId">
        <ContractPlanSelect
          :mode="isEdit ? 'update' : 'create'"
          :shopPlan="formData.ContractPlan"
          :model.sync="formData.ContractPlanId"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton
        plain
        @click=";(showDialog = false), resetForm()"
      >
        {{ $t('common.button.cancel.text') }}
      </BaseElButton>
      <BaseElButton v-loading="loading" type="primary" @click="onConfirm">
        {{
          displayData.modal.btn.confirm
        }}
      </BaseElButton>
    </div>

    <ImageCropper
      v-if="modal.cropper"
      :image="imgHolder"
      @close="modal.cropper = false"
      @uploaded="getCroppedImage"
    />
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, inject, onBeforeMount, reactive, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { CreateShop, GetShopById, UpdateShop } from '@/api/shop'
import { find, some, get } from 'lodash'
import store from '@/store'
import notifyMessage from '@/config/notifyMessage'
import ContractPlanSelect from '@/components/Select/ContractPlanSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import { useOrg } from '@/use/useOrg'
import { currencyOptions, timezoneOptions } from '@/config/branchSetting'
import { i18n } from '@/plugins/i18n/i18n'
import { emailRulesNoRequired, noEmptyRules, phoneRules } from '@/validation'
import ImageCropper from '@/components/ImageCropper.vue'
import { apiFormatAdaptor } from '@/utils/api'

export default defineComponent({
  name: 'EditBranchShopModal',
  components: {
    BaseDialog,
    ContractPlanSelect,
    UploadButton,
    ImageCropper,
  },
  props: {
    selectRow: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const industryCategories = inject('industryCategories')
    const { formData, formRef, initFormData, loading, checkForm } = useBaseForm()
    const { dashboardConfig } = useOrg()
    const loaded = ref(false)
    const modal = reactive({
      cropper: false,
    })
    initFormData({
      img: null,
      name: '',
      phone: '',
      address: '',
      email: '',
      industryCategory: '',
      industry: '',
      ContractPlanId: '',
      googleMapLink: '',
      currency: 'TWD',
      timezone: 'Asia/Taipei',
    })

    const subIndustryCategories = ref([])
    const imgHolder = ref(null)

    const isEdit = computed(() => get(props.selectRow, 'id'))
    const isOverseasOrg = computed(() => get(store.state, 'org.isOverseasShop'))
    const showControl = computed(() => {
      const controls = {
        formItem: {
          timezone: false,
          currency: true,
        },
        formItemHint: {
          timezone: true,
        },
      }

      if (get(dashboardConfig.value, 'orgModulePermission.enableShopTimezone')) {
        controls.formItem.timezone = true
      }
      if (isEdit.value) {
        controls.formItemHint.timezone = false
      }
      if (!isOverseasOrg.value) {
        controls.formItem.currency = false
      }

      return controls
    })

    const disabledControl = computed(() => {
      const controls = {
        formItem: {
          currency: false,
          timezone: false,
        },
      }

      if (isEdit.value) {
        controls.formItem.currency = true
        controls.formItem.timezone = true
      }

      return controls
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        phone: [noEmptyRules(), phoneRules(true, true, true)],
        address: [noEmptyRules()],
        // prefecture: [noEmptyRules()],
        // city: [noEmptyRules()],
        // detailAddress: [noEmptyRules()],
        email: [emailRulesNoRequired()],
        currency: [noEmptyRules()],
        timezone: [noEmptyRules()],
        ContractPlanId: [noEmptyRules()],
      }

      return rules
    })

    const displayData = computed(() => {
      const data = {
        modal: {
          title: isEdit.value ? i18n.t('branchSetting.dialog.edit.title') : i18n.t('branchSetting.dialog.create.title'),
          btn: {
            confirm: isEdit.value ? i18n.t('common.button.save.text') : i18n.t('common.button.create.text'),
          },
        },
        formItemHint: {
          timezone: i18n.t('branchSetting.dialog.timezone.hint'),
        },
      }
      return data
    })

    const submitPayload = computed(() => {
      const payload = {
        shopId: get(props.selectRow, 'id'),
        imageId: formData.img?.id || null,
        name: formData.name,
        phone: formData.phone || undefined,
        address: formData.address || undefined,
        email: formData.email || undefined,
        industry: formData.industry || undefined,
        contractPlanId: formData.ContractPlanId !== 'none' ? formData.ContractPlanId : undefined,
        googleMapLink: formData.googleMapLink || undefined,
        currency: isOverseasOrg.value ? formData.currency : null,
        timezone: formData.timezone || undefined,
      }
      return payload
    })

    const syncFormData = async () => {
      const [res, err] = await GetShopById(props.selectRow.id)
      if (err) return window.$message.error(err || err.msg)

      formData.img = res.Image || undefined
      formData.name = res.name
      formData.phone = res.phone
      formData.address = res.address
      formData.email = res.email
      formData.industryCategory = res.industryCategory
      formData.industry = res.industry
      formData.ContractPlanId = get(res, 'ContractPlan.id')
      formData.googleMapLink = res.googleMapLink
      formData.currency = res.currency
      formData.timezone = res.timezone || 'Asia/Taipei'

      if (res.industry) {
        const industryCategory = find(industryCategories.value, category =>
          some(category.industries, industry => industry.key === res.industry),
        )
        formData.industryCategory = industryCategory.key
        subIndustryCategories.value = industryCategory.industries
      }

      // if (isOverseasShop.value && config.address) {
      //   const address = config.address.split(',')
      //   formData.prefecture = address[0]
      //   formData.city = address[1]
      //   formData.detailAddress = address[2]
      // }
    }

    const updateIndustry = () => {
      formData.industry = ''
      const sub = find(industryCategories.value,
        (category) => category.key === formData.industryCategory,
      )
      subIndustryCategories.value = sub.industries
    }

    const onImaChange = (img) => {
      imgHolder.value = img
      modal.cropper = true
    }

    const getCroppedImage = (e) => {
      formData.img = e
      modal.cropper = false
    }

    const onConfirm = async () => {
      loading.value = true
      const formPass = await checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }

      let apiMethod = apiFormatAdaptor(CreateShop)
      let successMsg = notifyMessage.createSuccess
      if (isEdit.value) {
        apiMethod = apiFormatAdaptor(UpdateShop)
        successMsg = notifyMessage.updateSuccess
      }

      const [shop, errMsg, rawErr] = await apiMethod(submitPayload.value)
      loading.value = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success(successMsg)
      if (!isEdit.value) {
        await store.dispatch('user/addAllowShop', shop)
      }

      emit('refresh')
      emit('close')
    }

    onBeforeMount(async () => {
      if (isEdit.value) await syncFormData()
      loaded.value = true
    })

    return {
      loaded,
      displayData,
      formRules,
      formData,
      formRef,
      loading,
      onConfirm,
      showControl,
      currencyOptions,
      timezoneOptions,
      disabledControl,
      subIndustryCategories,
      industryCategories,
      updateIndustry,
      isEdit,
      onImaChange,
      modal,
      imgHolder,
      getCroppedImage,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
